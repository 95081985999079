/*
    - PROGRESS CIRCLE
    - Archive courses
    - Course Curriculum
    - BREADCRUMB
    - COURSE PROGRESS
    - COURSE TABS
    - POPUP STYLE
    - COURSE QUIZ
    - Form field
    - jAlerts
    - tooltip
    - fill in blank
    - Shortcode/Widgets
 */

@icon-url: '../fonts/lp-icons';
@font-face {
  font-family: 'lp-icons';
  src: url('@{icon-url}.eot?f41nl');
  src: url('@{icon-url}.eot?f41nl#iefix') format('embedded-opentype'),
  url('@{icon-url}.ttf?f41nl') format('truetype'),
  url('@{icon-url}.woff?f41nl') format('woff'),
  url('@{icon-url}.svg?f41nl#lp-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="lp-icon-"], [class*=" lp-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lp-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lp-icon-file-text2:before {
  content: "\e926";
}

.lp-icon-file-picture:before {
  content: "\e927";
}

.lp-icon-file-music:before {
  content: "\e928";
}

.lp-icon-file-video:before {
  content: "\e92a";
}

.lp-icon-file-zip:before {
  content: "\e92b";
}

.lp-icon-paste:before {
  content: "\e92d";
}

.lp-icon-alarm:before {
  content: "\e950";
}

.lp-icon-cross:before {
  content: "\ea0f";
}

.lp-icon-checkmark:before {
  content: "\ea10";
}

.lp-icon-spinner2:before {
  content: "\e97b";
}

.lp-icon-plus:before {
  content: "\ea0a";
}

.lp-icon-minus:before {
  content: "\ea0b";
}

.clearfix() {
  display: block;
  content: '';
  clear: both;
}

.clearfix:after {
  display: block;
  content: '';
  clear: both;
}

.border-radius(@args) {
  -webkit-border-radius: @arguments;
  -khtml-border-radius: @arguments;
  -moz-border-radius: @arguments;
  -ms-border-radius: @arguments;
  -o-border-radius: @arguments;
  border-radius: @arguments;
}

.transform(@args) {
  -webkit-transform: @arguments;
  -khtml-transform: @arguments;
  -moz-transform: @arguments;
  -ms-transform: @arguments;
  -o-transform: @arguments;
  transform: @arguments;
}

.transition(@args) {
  -webkit-transition: @arguments;
  -khtml-transition: @arguments;
  -moz-transition: @arguments;
  -ms-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}

.cross(@size: 30px, @border: 3px, @color: #000) {
  &:before,
  &:after {
    width: @border;
    background: @color;
    height: @size;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: (@size -  @border) / 2;
  }
  &:before {
    .transform(rotate(-45deg));
  }
  &:after {
    .transform(rotate(45deg));
  }
}

.animation(@args) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}

.overlay-processing {
  &:before, &:after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
  }
  &:before {
    background: #000;
    opacity: 0.5;
  }
  &:after {
    background: #FFF;
  }
}

/* PROGRESS CIRCLE */
.progress-circle(@size: 200px, @background: #DDD, @fill: #95e6f9, @initialize: 0, @inside-size: 170px, @inside-background: #FFF) {
  .progress-circle {
    width: @size;
    height: @size;
    border-radius: 50%;
    background-color: @background;
    position: relative;
    .background {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: @size;
      height: @size;
      clip: rect(0, @size, @size, @size / 2);
      .fill {
        content: "";
        position: absolute;
        border-radius: 50%;
        width: @size;
        height: @size;
        clip: rect(0, @size / 2, @size, 0);
        background: @fill;
        transform: rotate(unit(@initialize*3.6, deg));
      }
    }
    &:after {
      content: '';
      width: @inside-size;
      height: @inside-size;
      background: @inside-background;
      .border-radius(50%);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -@inside-size / 2;
      margin-left: -@inside-size / 2;
      pointer-events: none;
    }
    .inside {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: @size;
      text-align: center;
      z-index: 10;
    }
    &.gt-50 {
      background-color: @fill;
      .background {
        clip: rect(0, @size/2, @size, 0);
      }
      .fill {
        clip: rect(0, @size, @size, @size/2);
        background: @background;
      }
    }
  }
}

.clearfix() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.hide-if-js {
  display: none !important;
}

/* Archive courses */
.learn-press-courses {
  list-style: none;
  clear: both;
  margin: 0 -30px 0 0;
  padding: 0;
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  li.course {
    list-style-type: none;
    float: left;
    width: 23%;
    margin: 0 2% 30px 0;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid #DDD;
    background: #FFF;

    .view-more {
      padding: 0 15px;
      text-decoration: none;
      box-shadow: none;
    }

    h3 {
      padding: 15px;
      margin: 0;
      font-size: 18px;
      line-height: 1.5;
    }
    .course-thumbnail {
      position: relative;
      img {
        max-width: 100%;
        height: auto;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
    .course-info {
      padding: 0 15px 15px;
      font-size: 14px;
      overflow: hidden;
    }
    .course-students {
      float: left;
    }
    .course-instructor {
      margin-bottom: 20px;
    }
    .course-origin-price {
      float: right;
      text-decoration: line-through;
      padding-right: 5px;
    }
    .course-price {
      float: right;
      color: rgb(84, 181, 81);
    }
    .course-info > * {
      display: block;
    }
  }
}

.course-origin-price {
  text-decoration: line-through;
  padding-right: 5px;
}

.course-price {
  color: rgb(84, 181, 81);
}

.learn-press-notice .button {
  float: right;
}

.learn-press-form {
  margin-bottom: 20px;
}

/* Course Curriculum */

#learn-press-course-curriculum {
  ul.curriculum-sections,
  ul.section-content {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #F5F5F5;
    li.section {
      list-style-type: none;
    }
  }
  .section-header {
    h4 {
      display: inline;
    }
    background-color: #EEE;
    margin: 0;
    padding: 20px 15px;
    border-top: 1px solid #FFF;
    position: relative;
    p {
      display: inline-block;
      font-weight: normal;
      font-style: italic;
      color: rgb(153, 153, 153);
      font-size: 14px;
      margin: 0;
      padding: 10px 0;
      text-transform: none;
    }
    &:hover {
      background: #DDD;
    }
    .meta {
      font-size: smaller;
      font-weight: normal;
      position: absolute;
      right: 15px;
      top: 20px;
      .collapse {
        display: inline-block;
        position: relative;
        font-family: 'lp-icons';
        background: #95e6f9;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #FFF;
        border-radius: 5px;
        vertical-align: middle;
        margin-left: 5px;
        font-size: 10px;
        &:before {
          content: "\ea0b";
          margin: 0 auto;
        }
        &:hover {
          cursor: pointer;
        }
        &.plus {
          &:before {
            content: "\ea0a";
          }
        }
      }
    }
  }
  .course-item {
    margin: 0;
    list-style: none;
    padding: 10px 15px;
    border-top: 1px solid rgb(255, 255, 255);
    position: relative;
    .transition(background linear 0.5s);
    .lp-label-viewing,
    .lp-label-completed {
      display: none;
    }
    .course-item-title {
      display: block;
      box-shadow: none;
      text-align: justify;
      float: left;
      &:before {
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        font-family: 'lp-icons';
      }
    }
    .lp-icon {
      display: none;
    }
    .item-status {
      display: none;
      font-family: dashicons;
      .border-radius(4px);
      background: #DDD;
      color: #22b4ff;
      font-size: 18px;
      &:before {
        content: "\f177";
      }
      &.item-status-viewed {

      }
      &.item-status-started {
        &:before {
          content: "\f469";
        }
      }
      &.item-status-completed {
        background: #22b4ff;
        color: #FFF;
        &:before {
          content: "\f147";
        }
      }
      &.item-status-passed {
        background: #22b4ff;
        color: #FFF;
        &:before {
          content: "\f147";
        }
      }
      &.item-status-failed {
        background: #cc540d;
        color: #FFF;
        &:before {
          content: "\f335";
        }
      }
    }
    .item-result {
      display: none;
    }
    &.item-has-status {
      .item-status {
        display: inline-block;
      }
    }
    &.item-has-result {
      .item-result {
        display: inline-block;
      }
    }
    &.viewable {
      cursor: pointer;
      &:hover {
        background: #e7ecfb;
        .transition(background linear 0s);
      }
    }
    &.course-lesson {
      .course-item-title {
        &:before {
          content: '\e926';
        }
      }
    }
    &.course-quiz {
      .course-item-title {
        &:before {
          content: '\e950';
        }
      }
    }
    &.item-current {
      background: #FFF;
      &:before {
        display: block;
        width: 3px;
        background: #95e6f9;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        content: '';
      }
    }

    &.focus {
      background: #ffb710;
      &.off {
        .transition(none);
      }
    }
    &:after {
      display: block;
      clear: both;
      content: '';
    }
  }

  .course-item-meta {
    display: block;
    line-height: 1;
    top: 15px;
    right: 15px;
    float: right;
    margin-top: 5px;
    .item-loop-meta-text {
      font-size: small;
      &.item-final {
        color: #ffffff;
        background: #cc540d;
        padding: 3px 8px;
        .border-radius(3px);
      }
    }
  }
}

.lp-icon {
  display: inline-block;
  font-family: 'lp-icons';
  background: #95e6f9;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #FFF;
  border-radius: 5px;
  vertical-align: middle;
  &:before {
    width: 32px;
    height: 32px;
  }

}

.lp-label {
  display: inline-block;
  line-height: 24px;
  font-size: 78%;
  vertical-align: middle;
  color: #ffffff;
  padding: 0 10px;
  background: #DDD;
  height: 24px;
  .border-radius(4px);
  &.lp-label-viewing {
    background-color: rgb(57, 175, 255);
  }
  &.lp-label-completed {
    background-color: #39c7ce;
  }
  &.lp-label-lesson {
    background-color: #337ab7;
  }
  &.lp-label-quiz {
    background-color: #53658c;
  }
  &.lp-label-preview {
    background-color: #02a7ce;
  }
}

.learn-press-content-protected-message {
  border: 3px solid #ff8484;
  background: #FFF;
  padding: 20px;
  color: #ff8484;
  margin: 10px 0;
  border-radius: 6px;
  .icon {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
    .cross(30px, 3px, #ff8484);
  }
}

/** BREADCRUMB */
.learn-press-breadcrumb {
  margin-bottom: 20px;
}

/** COURSE PROGRESS **/
.single-lp_course {
  .learn-press-course-results-progress {
    margin-bottom: 40px;
    .items-progress {
      float: left;
      width: 68%;
      margin-right: 2%;
      clear: left;
    }
    .lp-course-progress-heading {
      margin-bottom: 10px;
    }
    .course-progress {
      float: left;
      width: 30%;
    }
    .number, .percentage-sign {
      display: inline-block;
      vertical-align: bottom;
      line-height: 1;
      font-size: 25px;
    }
    .percentage-sign {
      margin-left: 5px;
    }
    .grade {
      font-size: 12px;
      font-weight: bold;
      background: #F5F5F5;
      padding: 2px 7px;
      .border-radius(3px);
      &.in-progress {

      }
      &.passed {
        color: #ffffff;
        background: #95e6f9;
      }
      &.failed {
        color: #ffffff;
        background: #ff5425;
      }
    }
    &:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}

.lp-course-progress {
  position: relative;
  .lp-progress-bar {
    height: 15px;
    background: #DDD;
    margin: 10px 0 15px 0;
    overflow: hidden;
    .border-radius(3px);
    .lp-progress-value {
      width: 50%;
      height: 15px;
      background: #95e6f9;
    }
  }
  .lp-passing-conditional {
    left: 0%;
    position: absolute;
    height: 25px;
    top: -5px;
    margin-left: -5px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      border: 5px solid transparent;
      left: 0;
      position: absolute;
    }
    &:before {
      border-top-color: #DDD;
    }
    &:after {
      border-bottom-color: #DDD;
      bottom: 0;
    }
  }
}

#learn-press-content-item {
  border: 1px solid #DDD;
  display: none;

}

/** COURSE TABS **/
.learn-press-tabs {
  .learn-press-nav-tabs {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #DDD;
    .learn-press-nav-tab {
      list-style-type: none;
      float: left;
      margin-bottom: -1px;
      position: relative;
      a {
        display: inline-block;
        padding: 10px 15px;
        outline: none;
        box-shadow: none;
        color: #686868;
        border-bottom: 1px solid #DDD;
      }
      &.active {
        border-bottom: 4px solid #007acc;
        a {
          color: #007acc;
          border-bottom: none;
          margin-bottom: -4px;
        }
      }
      &.active:after {
        position: absolute;
        width: 100%;
        border-bottom: 1px solid #FFF;
        bottom: -1px;
        content: '';
      }
    }
    &:hover {

    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .learn-press-tab-panel {
    display: none;
    margin-bottom: 20px;
    &.active {
      display: block;
    }
  }
}

.learn-press-block-content-mixin(@w) {
  &:before {
    width: (@w);
    height: (@w);
    margin-left: -(@w/2);
    margin-top: -(@w/2); /*-76px;*/
    -webkit-clip-path: polygon(0px @w, @w @w, @w @w/2, @w/2 @w/2, @w/2 0, 0 0);
  }

  span {
    clip: rect(0, @w+4, @w/2+2, @w/2+2);
    width: @w+4;
    height: @w+4;
    margin-left: -(@w/2+2);
    margin-top: -(@w/2+2);
    &:before {
      width: @w+4;
      height: @w+4;
    }
  }
}

.learn-press-course-buttons {

  a.button {
    display: inline-block;
    font-size: 16px;
    background: #1a1a1a;
    border: 0;
    border-radius: 2px;
    color: #fff;
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    font-weight: 700;
    letter-spacing: 0.046875em;
    line-height: 1;
    padding: 0.84375em 0.875em 0.78125em;
    text-transform: uppercase;

    &:hover {
      background: #007acc;
    }
  }
}

/* POPUP STYLE */

#popup_overlay {
  z-index: 10000000 !important;
}

#popup_container {
  z-index: 10000001 !important;
}

body.course-has-popup {
  overflow: hidden;
}

#course-curriculum-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 999999;
  * {
    box-sizing: border-box;
  }
  #popup-sidebar {
    float: left;
    width: 350px;
    position: absolute;
    top: 60px;
    bottom: 0;
    overflow: auto;
    background: #FFF;
    border-right: 1px solid #DDD;
    .course-curriculum-title {
      display: none;
    }
    .curriculum-sections {
      margin: 0;
      font-size: smaller;
    }
    .learn-press-course-results-progress {
      padding: 15px 20px;
      margin-bottom: 0;
      .items-progress, .course-progress {
        width: 100%;
        margin: 0 !important;
      }
    }
  }
  #popup-main {
    position: absolute;
    top: 0;
    left: 350px;
    right: 0;
    bottom: 0;
    background: #FFF;
    #popup-header {
      position: absolute;
      top: 0;
      left: -350px;
      right: 0;
      height: 60px;
      background: #cc540d;
      border-bottom: 1px solid #ab4a10;
      .popup-menu {
        position: absolute;
        height: 60px;
        width: 350px;
        border-right: 1px solid #ab4a10;
      }
      .popup-close,
      .sidebar-hide-btn,
      .sidebar-show-btn {
        width: 59px;
        height: 59px;
        position: absolute;
        top: 0;
        cursor: pointer;
        font-size: 34px;
        line-height: 60px;
        text-align: center;
        color: #FFF;
        &:hover {
          background: #b34c10;
        }
      }
      .sidebar-hide-btn {
        left: 0;
      }
      .sidebar-show-btn {
        position: relative;
        margin-left: -20px;
        margin-right: 20px;
      }
      .popup-close {
        right: 0px;
        &:before,
        &:after {
          content: '';
          border-top: 3px solid #FFF;
          width: 30px;
          display: inline-block;
          position: absolute;
          top: 29px;
          left: 17px;
        }
        &:before {
          -webkit-transform: rotate(45deg);
        }
        &:after {
          -webkit-transform: rotate(-45deg);
        }
        &:hover {
          background: #b34c10;
          &:before, &:after {
            border-color: #FFF;
          }
        }
      }
      .popup-title {
        margin: 0 0 0 350px;
        height: 60px;
        line-height: 60px;
        padding: 0 20px;
        color: #FFF;
        position: relative;
      }

    }
    #popup-content {
      position: absolute;
      top: 60px;
      bottom: 50px;
      left: 0;
      right: 0;
      overflow: auto;
      transition: top linear 0.25s, bottom linear 0.25s;

      #popup-content-inner {
        margin: 20px 40px;
      }
    }
    #popup-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background: #F5F5F5;
      border-top: 1px solid #DDD;
      .footer-control {
        display: block;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        background: #FFF;
        &.prev-item {
          float: left;
        }
        &.next-item {
          float: right;
        }
      }
    }
    #popup-header, #popup-footer {
      transition: opacity linear 0.25s;
    }
    &.expand {
      position: fixed;
      left: 0;
      padding: 0 20%;
      top: 0;
      bottom: 0;
      #popup-header,
      #popup-footer {
        opacity: 0;
      }
      #popup-content {
        top: 0;
        bottom: 0;
      }
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
    margin: 0;
    background: #FFF;
  }
}

.learn-press-content-item-title {
  margin-bottom: 20px;
  border-bottom: 2px solid #DDD;
  padding-bottom: 20px;
  &.content-item-quiz-title {
    h4 {
      float: left;
      font-size: 24px;
      margin-bottom: 0;
    }
    .quiz-countdown {
      float: right;
      position: relative;
      min-width: 100px;
      background: #F5F5F5;
      text-align: center;
      font-size: 18px;
      &:after, &:before {
        padding: 0 10px;
        height: 3px;
        position: absolute;
        bottom: -3px;
        background: #000;
        content: '';
        left: 0;
      }
      &:before {
        width: 100%;
      }
      &:after {
        background: #00adff;
        padding: 0;
      }
      &.warning-time-over {
        &:after {
          background: #FF0000;
        }
      }
      .countdown {

      }
    }
    .clearfix();
  }
  .lp-expand {
    float: right;
    width: 43px;
    height: 43px;
    line-height: 43px;
    background: #F5F5F5;
    margin-left: 10px;
    border: 1px solid #DDD;
    outline: none;
  }
}

.learn-press-content-item-summary {
  /*padding: 20px;
  border-top: 1px solid #DDD;*/
  margin-bottom: 30px;
  .quiz-question-title {
    margin-bottom: 20px;
  }
  .learn-press-question-explanation, .learn-press-question-hint {
    background: #F5F5F5;
    padding: 15px 20px;
    font-style: italic;
    margin-bottom: 20px;
    .hint-title, .explanation-title {
      font-style: normal;
    }
  }
}

.button-finish-quiz {
  float: right;
}

.learnpress form.purchase-course {
  margin-bottom: 20px;
}

#learn-press-block-content {
  display: none;
  width: 100%;
  height: 100%;
  background: fade(#FFF, 25%);
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  &:before, &:after, span {
    position: absolute;
    .border-radius(50%);
    left: 50%;
    top: 50%;
    box-sizing: border-box;
  }
  &:before {
    content: '';
    border: 2px solid #000;
    z-index: 10;
    .animation(spin-13 1.5s infinite);
  }
  &:after {
    z-index: 0;
    content: '';
    border: 10px solid #000000;
    margin-left: -80px;
    margin-top: -80px;
    width: 160px;
    height: 160px;
    opacity: 0;
  }
  span {
    z-index: 20;
    .animation(spin-12 1.5s infinite);
    &:before {
      content: '';
      border: 6px solid #95e6f9;
      position: absolute;
      .border-radius(50%);
      box-sizing: border-box;
    }
  }

  .learn-press-block-content-mixin(80px);
}

body.block-content {
  #learn-press-block-content {
    display: block;
  }
}

/* COURSE QUIZ */
.quiz-buttons {
  margin-bottom: 20px;
  clear: both;
}

.quiz-intro {
  list-style: none;
  padding: 0;
  margin-left: 0;
  li label {
    display: inline-block;
  }
}

.quiz-questions-list {
  margin: 0 0 20px 0;
  padding: 0;
  list-style-position: inside;
  li {
    margin: 0 0 10px 0;
  }
  .question-title {
    margin: 0 0 5px 0;
    display: inline-block;
    font-size: 14px;
  }
  .current {
    .question-title {
      font-weight: bold;
    }
  }
  input[type="checkbox"], input[type="radio"] {
    border: 1px solid #DDD;
    display: inline-block;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    position: relative;
    &:checked {
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
      }
    }
  }
  input[type="checkbox"]:checked {
    &:after {
      border-bottom: 3px solid #00b9eb;
      border-right: 1px solid #00b9eb;
      -webkit-transform: rotate(14deg);
      -moz-transform: rotate(14deg);
      -ms-transform: rotate(14deg);
      -o-transform: rotate(14deg);
      transform: rotate(33deg);
      width: 8px;
      height: 16px;

      top: -5px;
      left: 5px;
    }
  }
  input[type="checkbox"] {
    &:disabled {
      &:after {
        border-color: #DDD;
      }
    }
  }
  input[type="radio"] {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    &:checked {
      &:after {
        background: #00b9eb;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin-left: -4px;
        margin-top: -4px;
      }
    }
    &:disabled {
      &:after {
        background: #DDD;
      }
    }
  }
}

.question-results {
  .question-title {
    &:after {
      font-family: dashicons;
      font-size: 24px;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      margin-left: 5px;
    }
  }
  &.correct {
    .question-title {
      &:after {
        content: '\f147';
        color: #00adff;
      }
    }
  }
  &.skipped {
    .question-title {
      &:after {
        content: '\f534';
        color: #c6c6c6;
      }
    }
  }
  &.incorrect {
    .question-title {
      &:after {
        content: '\f335';
        color: #ff5425;
      }
    }
    .user-answer-false {
      .option-title {
        text-decoration: line-through;
      }
    }
  }
  .answer-true {
    color: #007acc;
    .option-title {
      background: #fff9d8;
      padding: 0 5px;
      margin-left: -5px;
    }
  }
  input {
    vertical-align: middle;
  }
}

.quiz-description,
.question-content {
  .learn-press-question-title {
    margin-bottom: 20px;
  }
}

.quiz-countdown {
  float: right;
  position: relative;
  min-width: 150px;
  background: #F5F5F5;
  text-align: center;
  font-size: 18px;
  padding: 5px 0;
}

#quiz-countdown {
  .progress-circle {
    overflow: hidden;
    box-shadow: 0 0 3px #bdbdbd inset;
    .inside {
      line-height: 1;
      .countdown {
        font-size: 48px;
        color: #087998;
        _text-shadow: 1px 1px #8ee5fd;
        margin-top: 110px;
      }
      p {
        margin: 10px 0 0 0;
        color: #DDD;
      }
    }
    &:after {
      background: #00b9eb;
      box-shadow: 0 0 6px #097a98;
    }
  }
}

#quiz-progress {
  .progress-circle(@size: 200px, @background: #DDD, @fill: #FF0000, @initialize: 0%, @inside-size: 170px, @inside-background: #FFF);
}

.quiz-result {
  .quiz-result-mark {
    .progress-circle();
    margin-bottom: 20px;
  }
  .quiz-result-summary {
    margin-bottom: 20px;
    max-width: 500px;
    .quiz-result-field {
      position: relative;
      background: #F5F5F5;
      color: #fff;
      margin: 0 0 8px 0;
      font-size: 12px;
      padding: 5px 10px;
      .border-radius(4px);
      overflow: hidden;

      span, span:after, &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        content: attr(data-text);
        color: #FFF;
        white-space: nowrap;
        overflow: hidden;
        padding: 3px 10px;
        background: transparent;
        transition: width 0.5s;
      }
      &:before {
        content: '';
        padding: 0;
      }
      span {
        padding: 0;
      }
      span:after {
        margin: 3px 10px;
        padding: 0;
        width: 100%;
      }
      &.correct {
        background: #22b4ff;
      }
      &.wrong {
        background: #ff5425;
      }
      &.empty {
        color: #656D6D;
      }
    }
  }
  p.quiz-result-time {
    margin: 0 0 10px 0;
  }
  .quiz-grade {
    p {
      margin: 0 0 10px 0;
      span {
        font-weight: bold;
        &.passed {
          color: #22b4ff;
        }
        &.failed {
          color: #ff5425;
        }
      }
    }
  }
}

.element-data-percent-loop (@i) when (@i <= 100) {
  .single-quiz .quiz-result-field[data-value="@{i}"]:before,
  .single-quiz .quiz-result-field[data-value="@{i}"] span,
  #quiz-countdown[data-value="@{i}"]:after {
    width: ~"@{i}%";
  }
  .element-data-percent-loop(@i + 1);
}

.element-data-percent-loop (0);

/* QUESTION */
.learn-press-question-options {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  > li.answer-option {
    font-size: 16px;
    background: #F5F5F5;
    margin-bottom: 20px;
    position: relative;
    .border-radius(4px);
    > label {
      display: block;
      input[type="checkbox"], input[type="radio"] {
        border: 0;
        -webkit-appearance: initial;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 4px;
        outline: none;
        position: absolute;
        top: 10px;
        left: 15px;
        margin: 0;
        &:checked:before {
          content: '';
          border: 4px solid #00b9eb;
          display: inline-block;
          width: 10px;
          height: 15px;
          border-top: none;
          border-left: none;
          position: absolute;
          top: 3px;
          left: 8px;
          box-sizing: content-box;
          .transform(rotate(36deg));
        }
        &:checked + p {
          font-weight: bold;
          background: #00b9eb;
          color: #FFF;
        }
      }
      input[type="checkbox"][disabled], input[type="radio"][disabled] {
        opacity: 1;
      }
      input[type="radio"] {
        .border-radius(50%);
      }
      p {
        min-height: 50px;
        padding: 5px 20px 5px 60px;
        margin: 0;
        box-sizing: border-box;
        line-height: 1.5;
        &.single-lines {
          padding: 0 20px 0 60px;
          line-height: 50px; /* middle */
        }
      }
    }
    label p {
      .border-radius(4px);
    }
    &.answer-true label p {
      background: #00b9eb;
      color: #FFF;
    }
    &:hover label p {
      background: #D4D4D4;
      cursor: pointer;
    }
    &.user-answer-false {
      background: #F5F5F5;
      > label {
        input[type="checkbox"] + p, input[type="radio"] + p {
          text-decoration: line-through;
          color: #FF0000;
          background: #F5F5F5;
        }
      }
    }
  }
  .answer-option-result {
    label {
      p {
        display: inline-block;
        margin: 0;
        font-size: smaller;
      }
      input[disabled] {
        opacity: 1;
      }
    }
  }
}

.learnpress-content {
  margin-bottom: 20px;
}

.course-meta {
  margin-bottom: 20px;
  .course-origin-price {
    padding-right: 5px;
    text-decoration: line-through;
  }
  .course-price {
    font-weight: bold;
  }

}

/** MESSAGE */
.learn-press-message {
  background: #F5F5F5;
  border-left: 5px solid #00A0D2;
  padding: 10px 20px 0px 20px;
  margin-bottom: 20px;
  overflow: hidden;
  button {
    float: right;
  }
  p {
    margin-bottom: 10px;
  }
  &:after {
    clear: both;
    content: '';
    display: block;
  }
  &.notice {
    border-left-color: #b3d20a;
  }
  &.error {
    border-left-color: #b81c23;
  }
  ul {
    list-style-type: none;
  }
}

/**/
.learn-press-checkout-comment,
.learn-press-checkout-payment {
  margin-bottom: 20px;
}

.learn-press-checkout-payment {
  .payment-methods {
    list-style: none;
    margin: 0;
  }
}

/* COURSE QUIZ */
.single-quiz {
  .quiz-questions-list {
    padding: 0;
  }
  .quiz-intro {
    list-style: none;
    padding: 10px 20px;
    background: #F5F5F5;
    margin: 0 0 20px 0;
  }
  h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.learn-press-tooltip {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  background: #0f4956;
  cursor: pointer;
  line-height: 1;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-weight: 700;
  position: relative;
  &:before {
    content: "?";
    font-size: 7px;
    color: white;
    position: absolute;
    top: 2px;
    left: 3px;
  }
}

/* Form field */
.lp-form-field-wrap {
  list-style: none;
  margin: 0 0 20px 0;
  .lp-form-field {
    list-style: none;
    margin: 0 0 20px 0;
    .lp-form-field-label {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .lp-form-field-input .description {
      font-size: smaller;
      font-style: italic;
      margin-top: 5px;
    }
    .lp-field-error-message {
      color: #FF0000;
    }
  }
}

.learn-press-user-profile {
  #learn-press-user-info {
    overflow: hidden;
    .user-avatar {
      width: 150px;
      float: left;
      .avatar {
        border-radius: 0;
        width: 100%;
      }
    }
    .user-basic-info {
      margin-left: 170px;
    }
  }
  .lp-edit-profile {
    &:after {
      .clearfix();
    }
  }
  .user-basic-info {
    p {
      margin-top: 10px;
    }

  }
  .learn-press-tabs {
    list-style: none;
    margin: 30px 0 20px;
    border-bottom: 1px solid #DDD;
    & > li {
      float: left;
      list-style-type: none;
      margin-bottom: -1px;
      & > a {
        display: inline-block;
        padding: 10px 15px;
        outline: none;
        box-shadow: none;
        color: #686868;
        border-bottom: 1px solid #DDD;
      }
      &.current {
        border-bottom: 4px solid #007acc;
        a {
          color: #007acc;
          margin-bottom: -4px;
        }
      }
    }
  }
  .learn-press-subtabs {
    & > li {
      display: inline-block;
      margin-right: 10px;
      &.current {
        font-weight: bold;
      }
    }
  }
  .learn-press-subtab-content {
    display: none;
    margin-bottom: 20px;
    &.current {
      display: block;
    }
    li.course {
      h3 {
        padding-bottom: 10px;
      }
    }
    .profile-courses {
      list-style-type: none;
      .learn-press-course-results-progress {
        padding: 0 15px 15px 15px;
        margin: 0;
      }
    }
  }
  button.learn-press-course-wishlist {
    display: block;
  }

  .user-profile-edit-form {

    .info-field {
      margin-bottom: 20px;
    }

    .description {
      margin-top: 5px;
    }

    .user-profile-picture {
      clear: both;
      .picture, .change-picture {
        display: inline-block;
      }
      .change-picture {
        .description {
          margin: 0 0 10px 0;
        }
      }
      img {
        float: left;
        margin-right: 20px;
      }
      &:after {
        .clearfix();
      }
    }

    .profile-field-name {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .change-password {
      margin-bottom: 10px;
    }

    #user_profile_password_form {
      input {
        margin-bottom: 20px;
      }
      .description {
        margin-top: -15px;
      }
    }

  }
  .update-profile {
    border-top: 2px solid #DDD;
    padding-top: 20px;
  }
  .learn-press-subtab-content {
    display: block;
  }
  #lp-user-edit-avatar {
    .moxie-shim {
      display: none;
    }
    .lp-form-field-avatar {
      float: left;
    }
    #lp-avatar-actions {
      margin: 20px 0;
    }
  }
  .lp-avatar-preview {
    position: relative;
    float: left;
    background-color: #DDD;
    .profile-picture {
      float: left;
      margin-right: -100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
      &.profile-avatar-hidden {
        display: none;
      }
    }
    .lp-avatar-preview-actions {
      position: absolute;
      top: 50%;
      width: 100%;
      margin-top: -14px;
      text-align: center;
      a {
        text-decoration: none;
        font-size: 12px;
        background: #FFF;
        display: inline-block;
        box-shadow: none;
        padding: 5px 10px;
      }
    }
    .lp-avatar-upload-progress {
      position: absolute;
      height: 10px;
      background: #FFF;
      top: 50%;
      margin-top: -5px;
      left: 10px;
      right: 10px;
      display: none;
      .lp-avatar-upload-progress-value {
        width: 0;
        height: 10px;
        background: #563d7c;
      }
    }
    .lp-avatar-upload-error {
      display: none;
    }
    .lp-avatar-preview-actions {
      display: none;
    }
    &:hover {
      .lp-avatar-preview-actions {
        display: block;
      }
    }
    &.uploading, &.upload-error {
      .lp-avatar-preview-actions {
        display: none;
      }
      .lp-avatar-upload-progress {
        display: block;
      }
      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #FFF;
        opacity: 0.4;
        content: '';
      }
    }
    &.upload-error {
      .lp-avatar-upload-error {
        display: block;
      }
    }
    &.croping {
      .lp-avatar-preview-actions {
        display: none;
      }
      .lp-avatar-crop-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        overflow: hidden;
        img {
          max-width: inherit;
          cursor: move;
        }
        .lp-crop-controls {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 30px;
          .lp-zoom {
            position: absolute;
            height: 10px;
            bottom: 10px;
            left: 10px;
            right: 30px;
            background: #563d7a;
            .ui-slider {
              position: absolute;
              left: 0;
              right: 10px;
              height: 100%;
              .ui-slider-handle {
                display: inline-block;
                width: 10px;
                height: 14px;
                background: #FFF;
                position: absolute;
                margin-top: -2px;
                border: 1px solid #563d7a;
                box-shadow: none;
                outline: none;
                cursor: ew-resize;
              }
            }
          }
          .lp-cancel-upload {
            text-decoration: none;
            position: absolute;
            bottom: 5px;
            right: 5px;
            box-shadow: none;
          }
          &:before {
            position: absolute;
            height: 30px;
            width: 100%;
            content: '';
            background: #FFF;
            opacity: 0.4;
          }
        }
      }
    }
    &:after {
      .clearfix();
    }
  }
}

body {
  &.profile-dragging {
    cursor: move !important;
  }
  &.profile-resizing {
    cursor: ew-resize !important;
  }
}

.learn-press-pagination {
  text-align: center;
  ul {
    list-style: disc;
    & > li {
      display: inline-block;
      span {
        display: inline-block;
        padding: 0 10px;
        border: 1px solid #DDD;
        background-color: #F5F5F5;
      }
      a {
        display: inline-block;
        padding: 0 10px;
        border: 1px solid #DDD;
        background-color: #FFF;
      }
    }
  }
}

.learn-press-become-teacher-form {
  ul {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 20px;
    }
  }
}

/* jAlerts */
#popup_container {
  background: #FFF;

  #popup_title {
    padding: 10px 20px;
    background: #18B6C7;
    color: #FFF;
    font-size: 18px;
    margin: 0;
  }

  #popup_content {
    padding: 20px;
    #popup_message {
      margin-bottom: 20px;
    }
  }

  #popup_panel {
    text-align: center;
  }
}

.learn-press-auto-redirect-next-item {
  display: none;

  &.active {
    display: block;
  }
}

.single-lp_course {
  .course-summary {
    .lp_course {
      .entry-footer {
        display: none;
      }
    }
  }
  .learn-press-message {
    position: relative;
    clear: both;
    margin-top: 25px;

    .learn-press-countdown {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      background: #cc540d;
      text-align: center;
      border-radius: 50%;
      line-height: 1.5em;
      font-size: 0.8em;
      color: #fff;
    }
    .learnpress-dismiss-notice {
      display: inline-block;
      position: absolute;
      right: 1em;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;

      &:before {
        font-size: 1.2em;
        color: red;
        content: "\f153";
        font-family: dashicons;
      }
    }

  }

}

/* tooltip */
.learn-press-tooltip-bubble {
  position: absolute;
  background: rgba(26, 26, 26, 0.9);
  color: #ffffff;
  padding: 8px 10px;
  font-size: 12px;
  z-index: 9999999;
  line-height: 1;
  border-radius: 3px;
}

/* fill in blank */
input.blank-fill-correct {
  width: auto;
}

body.content-item-only {
  background: #FFF;
  opacity: 0;
  .learn-press-content-item-only {
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    overflow: auto;
    padding: 20px;
    margin: 0;
  }

  #learn-press-content-item {
    border: none;
    display: block;
    transition: max-width linear 0.25s;
    margin: 0 auto;
    max-width: 1000px;
    width: 90%;
    &.expand {
      max-width: 1200px;
    }
  }
}

.lp-group-heading-title {
  &.toggle-off, &.toggle-on {
    cursor: pointer;
    .toggle-icon {
      display: inline-block;
      font-size: larger;
      line-height: 1;
      font-weight: bold;
      margin-left: 5px;
      &:before {
        content: '+';
      }
    }
  }
  &.toggle-on {
    .toggle-icon {
      &:before {
        content: '-';
      }
    }
  }
}

/*
.payment-method-form {
    &.payment_method_authorizenet {
        background: #f7f7f7;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        padding-top: 25px;
        .row-fluid {
            clear: both;
            padding-bottom: 25px;
            .span6 {
                &:first-child {
                    width: 50%;
                    float: left;
                    label {
                        margin-left: 17px;
                    }
                    select, input {
                        margin-left: 10px;
                    }
                }
                select, input {
                    padding: 8px 0;
                }
                select.required {
                    color: #000;
                }
            }
        }
    }
}*/

.place-order-action {
  margin-top: 20px;
}

.woocommerce {
  form .form-row {
    &.validate-email, &.validate-phone {
      width: 100% !important;
    }
  }

  .checkout_coupon {
    .form-row-last {
      input.button {
        padding: 13px 10px !important;
      }
    }
  }
}

.learn-press-search-course-form {
  margin-bottom: 20px;
  input.search-course-input[name="s"] {
    margin-right: -200px;
    width: 100%;
  }
  .search-course-button {
    vertical-align: middle;
    float: right;
    z-index: 10;
    position: relative;
  }
}

.table-orders {
  .order-status {
    &.order-status-removed {
      color: #FF0000;
    }
  }
}

/* Shortcode/Widgets */
.widget_lp-widget-popular-courses, .widget_lp-widget-recent-courses, .widget_lp-widget-featured-courses {
  .course-meta-data {
    > div {
      display: inline-block;
      &:after {
        content: '/';
      }
      &:last-child:after {
        content: '';
      }
    }

  }
  .course-title {
    margin: 5px 0;
  }
  .course-entry {
    margin-bottom: 20px;
    border-bottom: 1px dashed #DDD;
    padding-bottom: 20px;
  }
}

.edit-course-item-link {
  a {
    font-size: 14px;
    position: relative;
    padding-left: 25px;
    &:after {
      content: "\f464";
      font-family: Dashicons;
      font-size: 26px;
      position: absolute;
      bottom: 0;
      line-height: 1;
      left: 0;
    }
  }
}

.course-item-navigation {
  border-top: 4px solid #333;
  padding-top: 10px;
  margin-top: 20px;
  .nav-links {
    .nav-link {
      opacity: 1;
      a {
        opacity: 1;
        .meta-nav {
          display: block;
          text-transform: uppercase;
          font-size: small;
          color: #b9b9b9;
        }
        .post-title {
          opacity: 1;
        }
      }
      &.nav-previous {
        float: left;
      }
      &.nav-next {
        float: right;
        text-align: right;
      }
    }
    &:after {
      clear: both;
      display: block;
      content: '';
    }
  }
}

/* widget attributes */
.lp-course-attributes {
  list-style: none;
  margin-left: 0;
  li {
    list-style: none;
    margin-bottom: 10px;
  }
  .lp-course-attribute-values {
    list-style: none;
    margin-left: 0;
  }
  &.course-attributes {
    .lp-course-attribute-values {
      li {
        display: inline-block;
        padding: 3px 8px;
        background: #F0F0F0;
        border-radius: 4px;
        margin: 0 5px 5px 0;
      }
    }
  }
  &.course-filters {
    .lp-course-attribute-values {
      li {
        a:before {
          font-family: dashicons;
          border-radius: 50%;
          background: #DDD;
          display: inline-block;
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          color: #FFF;
          font-size: 16px;
          vertical-align: middle;
          margin-right: 5px;
          content: '';
        }
        &:hover a:before {
          background: #4acfff;
        }
      }
      li.active {
        a:before {
          content: "\f147";
          background: #4acfff;
        }
        &:hover a:before {
          content: "\f335";
          background: #a20000;
        }
      }
    }
  }
}

@-moz-keyframes spin-12 {
  0% {
    -moz-transform: rotate(0deg) translate(0px);
  }
  100% {
    -moz-transform: rotate(360deg) translate(0px);
  }
}

@-webkit-keyframes spin-12 {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
  }
  100% {
    -webkit-transform: rotate(360deg) translate(0px);
  }
}

@keyframes spin-12 {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
    transform: rotate(0deg) translate(0px);
  }
  100% {
    -webkit-transform: rotate(360deg) translate(0px);
    transform: rotate(360deg) translate(0px);
  }
}

@-moz-keyframes spin-13 {
  100% {
    -moz-transform: rotate(0deg) translate(0px);
  }
  0% {
    -moz-transform: rotate(360deg) translate(0px);
  }
}

@-webkit-keyframes spin-13 {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
  }
  100% {
    -webkit-transform: rotate(360deg) translate(0px);
  }
}

@keyframes spin-13 {
  100% {
    -webkit-transform: rotate(0deg) translate(0px);
    transform: rotate(0deg) translate(0px);
  }
  0% {
    -webkit-transform: rotate(360deg) translate(0px);
    transform: rotate(360deg) translate(0px);
  }
}

@media screen and (max-width: 1250px) {
  .single-lp_course .learn-press-course-results-progress {
    .items-progress, .course-progress {
      float: none;
      width: 100%;
    }
    .items-progress {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 768px) {
  #learn-press-block-content {
    .learn-press-block-content-mixin(60px);
  }

  .learn-press-course-results-progress {
    .items-progress, .course-progress {
      width: 100%;
      margin-right: 0;
    }
  }

}

@media (min-width: 992px) {
  .learn-press-courses {
    li.course {
      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }
}

@media (max-width: 992px) {
  .learn-press-courses {
    margin: 0;
  }
}

@media (max-width: 992px) and (min-width: 600px ) {

  .learn-press-courses {
    li.course {
      width: 31.33%;
      margin: 0 2% 30px 0;
      /* padding: 0 20px; */
      &:nth-child(3n+1) {
        clear: left;
        margin-left: 0;
      }
    }
  }

}

@media (max-width: 600px) and (min-width: 400px ) {

  .learn-press-courses {
    li.course {
      width: 48%;
      margin: 0 2% 30px 0;
      /* padding: 0 20px; */
      &:nth-child(2n+1) {
        clear: left;
        margin-left: 0;
      }
    }
  }

}

/*
@media (max-width: 480px) {
    .woocommerce {
        .checkout_coupon {
            .form-row {
                width: 100%;
            }
        }
    }
}
*/
@media (max-width: 400px) {

  .learn-press-courses {
    li.course {
      width: 100%;
      margin: 0 0 30px 0;
      clear: left;
    }
  }

}

@media (max-width: 350px) {
  #course-curriculum-popup #popup-sidebar {
    width: 100%;
  }
}

/* full width with twentysixteen theme */
@media screen and (min-width: 61.5625em) {
  body.single-lp_course {
    article.lp_course {
      .entry-content {
        width: 100%;
      }
      .entry-footer {
        display: none;
      }
    }
  }
}

/*
@media screen and (max-width: 615px) {
    .payment-method-form {
        &.payment_method_authorizenet {
            .row-fluid {
                padding-bottom: 15px;
                .span6 {
                    &:first-child {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    label {
                        margin-left: 17px;
                    }
                    select, input {
                        margin-left: 10px;
                    }
                    select, input {
                        padding: 8px 0;
                    }
                    select.required {
                        color: #000;
                    }
                    #learn-press-authorizenet-payment-expmonth {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
*/